.headerPrimary {
    display: block;
    height: 60px;
    /* border: 1px solid rgba(0, 0, 0, 0.7); */
    font-size: 24px;
    font-weight: 700;
    font-family: 'Gilroy';
    /* fill: color('rgba(44, 44, 44, 0.5)'); */
    color: #2E375B;

}

.headerPrimary p {
    margin-left: 7rem;
    padding-top: 0.6rem;
}

.headerSecondary {
    display: flex;
    flex-direction: row;
    /* margin: 2rem; */
    gap: 10px;
    height: 50px;
    align-items: center;
}

.headerSecondary img {
    width: 20px;
    height: 20px;
}


.headerSecondary .secondaryText {
    color: #444444;
    font-style: "Nunito";
    font-weight: 400;
    font-size: 14px;
}

.headerTernary {
    display: flex;
    flex-direction: row;
    background-color: #f5f5f5;
    justify-content: space-between;
    padding-right: 6.4rem;
}

.searchPanel {
    display: flex;
    flex-direction: row;
    margin-left: 6.4rem;
    padding-top: 2rem;
    gap: 15px;
}

.mainBodyFilter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.mainBodyFilter p {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    font-style: "Nunito";
    font-weight: 400;
    font-size: 14px;
}

.mainBodyFilter img {
    display: flex;
    align-items: center;
}

.handlingButtons {
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
    gap: 10px;
}

.excelDataBlock {
    display: flex;
    width: 100%;
    padding-left: 6.4rem;
    padding-top: 0.8rem;
    padding-right: 6.4rem;
    background-color: white;
    align-items: flex-start;
    justify-content: space-around;
    background: #f5f5f5;
    position: relative;
    height: auto;
}

.excelDataBlock table {
    table-layout: fixed;
    width: 100%;
    border: 1px solid rgb(232, 232, 232);
    padding-left: 6.4rem;
    padding-right: 6.4rem;
    font-style: "Nunito";
    font-weight: 700;
    font-size: 13px;
    /* border-bottom: 3px solid #38D68B; */
    background: #fff;
    /* height: 100vh; */
    flex: 1;
    z-index: 1;
    overflow-x: auto;
}

.excelDataBlock table th,
#users-data-body tr td {
    padding: 6px 6px;
    text-align: center;
    vertical-align: middle;
    margin-bottom: 10px;
    color: #2E375B;
    border-bottom: 3px solid #38D68B;
}

#users-data-body tr td {
    padding: 16px 16px;
    font-family: Nunito;
    font-size: 13px;
    font-weight: 400;
    /* line-height: 22px; */
    letter-spacing: 0em;

}

.excelDataBlock table th .text-wrap,
#users-data-body tr td .text-wrap {
    white-space: normal;
    /* Allow wrapping */
    word-break: break-word;
    /* Break words if needed */
}

.excelDataBlock table th img {
    padding-left: 10px;
    padding-bottom: 4px;

}

.uploadingDiv {
    z-index: 1;
    position: absolute;
}

#users-data-body tr td {
    border-bottom: none;
}

#users-data-body tr:nth-child(odd) {
    background-color: #F7F7F9;
}

.downloadBtnAndArrow {
    display: flex;
    width: 58px;
    height: 32px;
    border: 1px solid #FF9777;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 2px;
    margin-top: 3px;
    position: relative;
    /* display: inline-block; */
    cursor: pointer;
}

.dropdownDiv {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #E5E5E5;
    padding: 8px;
    width: 90px;
    border-radius: 8px;
    /* Set the width of the dropdown */
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    display: none;

}

.downloadBtnAndArrow:hover .dropdownDiv {
    display: block;
    z-index: 2;
}

.dropdown-menu li {
    list-style: none;
    padding: 6px;
    cursor: pointer;
}

/* Highlight the item on hover */
.dropdown-menu li:hover {
    background-color: #ddd;
}


.dropdownDivChecklist {
    width: 240px;
    /* height: 265px; */
    border-radius: 6px;
    background-color: #F7F7F9;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 4px 0px #00000040;
    position: absolute;
    z-index: 2;
    top: 8.5rem;
    /* display: none; */
    display: none;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transform: translateY(-10px);
}

.dropdownDivChecklist.show {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

.dropdownDivChecklist ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dropdownDivChecklist ul b {
    display: block;
    margin: 10px 10px 10px 15px;
    font-weight: bold;
    font-size: 14px;
}

.dropdownDivChecklist ul li {
    display: flex;
    flex-direction: row;
    gap: 4px;
    padding: 8px 20px;
    font-size: 14px;
    cursor: pointer;
    color: #444444;
    font-weight: 400;
    transition: background-color 0.3s;
}

.dropdownDivChecklist ul li:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    /* Semi-transparent background */
    z-index: 9999;
    /* Ensure it's on top of other content */
}


@media (min-width: 768px) and (max-width: 1024px) {
    .excelDataBlock {
        overflow-x: auto;
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }

    .excelDataBlock table {
        width: 100%;
        border-collapse: collapse;
        padding: 0rem;
        font-size: 10px;

    }

    .excelDataBlock table th,
    #users-data-body tr td {
        padding: 8px;
    }

    .handlingButtons {
        padding-left: 2rem;
    }

    .excelDataBlock table th .statusCol {
        width: 5rem;
    }
}

@media screen and (max-width: 768px) {
    .excelDataBlock {
        /* display: grid;
        grid-template-columns: repeat(12, 1fr); */
        /* overflow: auto; */
        /* padding-left: 9.5rem;
        padding-right: 0.5rem; */
        overflow-x: auto;
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }

    .excelDataBlock table {
        width: 100%;
        border-collapse: collapse;
        /* margin-left: 4rem; */
        font-size: 11px;
    }

    .excelDataBlock table th,
    #users-data-body tr td {
        padding: 8px;
    }

    /* .excelDataBlock {
        display: inline-block;
        overflow-x: auto;
         Enable horizontal scrolling on smaller screens 
    }

    .excelDataBlock table {
        width: 100%;
        border-collapse: collapse;
    }

    .excelDataBlock th,
    .excelDataBlock td {
        width: calc(100% / 12);
        padding: 8px;
        white-space: nowrap;
         Prevent text wrapping within cells 
        text-overflow: ellipsis;
        Display ellipsis (...) for overflowing text 
        overflow: hidden;
    }

    .excelDataBlock th img,
    .excelDataBlock td img {
        vertical-align: middle;
        margin-left: 5px;
    } */

    .searchPanel {
        margin-left: 3rem;
    }

    /* .handlingButtons {
        padding-left: 2.4rem;
    } */

    .downloadBtnAndArrow {
        margin-top: 4px;
        padding: 12px;
    }

}